<template>
    <div class="AuthUserLabel--wrapper">
        <div class="AuthUserLabel--text" v-if="show">
            {{ label }}
        </div>
    </div>
</template>

<script>

import {mapState} from "vuex";

export default {
    name: "AuthUserLabel",
    props: {
        show: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        ...mapState({
            user: state => state.user.user.data
        }),
        label() {
            return this.user.name.toLowerCase()
        }
    }
}
</script>

<style scoped lang="scss">
.AuthUserLabel {
    &--wrapper {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 101;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        -webkit-user-select: none;
        pointer-events: none;
    }

    &--text {
        color: #F2F2F280;
        font-weight: 500;
        font-size: 20px;
    }
}
</style>
