<template>
    <img v-if="src !== ''" :src="src" class="round-image" :alt="alt" :height="height" :width="width">
    <div
        v-else
        class="round-image round-image--default"
        :style="{width: `${width}px`, height: `${height}px`,}"
    >
        {{ firstLetters }}
    </div>
</template>

<script>
export default {
    name: "RoundImage",
    props: {
        src: {
            type: String,
        },
        username: {
            type: String,
            required: true,
        },
        alt: {
            type: String,
            default: 'image'
        },
        height: {
            required: true,
            type: [String, Number]
        },
        width: {
            required: true,
            type: [String, Number]
        }
    },
    computed: {
        firstLetters() {
            return this.username.slice(0, 2).toUpperCase()
        }
    }
}
</script>

<style scoped lang="scss">
.round-image {
    border-radius: 50%;
    border: solid 1px white;

    &--default {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        font-size: 12px;
        color: #FE4811;
    }
}
</style>
