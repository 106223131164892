<template>
    <app-transition>
        <Dialog @close="handleClose">
            <v-row>
                <v-col class="UnlockContent-message">
                    <p>{{ $t('dialogs.unlock_content') }} {{price}}{{currency}}?</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn outlined block height="50" @click="handleUnlock">{{ $t('dialogs.yes') }}</v-btn>
                </v-col>
                <v-col>
                    <v-btn outlined block height="50" @click="handleClose">{{ $t('dialogs.no') }}</v-btn>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>

<script>
import Dialog from "@/components/app/form/Dialog"
import {CURRENCY_SYMBOL} from "@/configs/constants"

export default {
    name: "unlockContentDialog",
    data: () => ({
        currency: CURRENCY_SYMBOL,
    }),
    props: {
        price: [Number, String],
    },
    methods: {
        handleClose() {
            this.$emit('close')
        },
        handleUnlock() {
            this.$emit('unlock')
        },
    },
    components: {
        Dialog,
    }
}
</script>
<style scoped lang="scss">
.UnlockContent {
    &-message {
        font-weight: bold;
        font-size: 20px;
    }
}

.v-btn {
    text-transform: capitalize;
    font-size: 1em !important;
    padding: 10px 28px !important;
}
</style>
