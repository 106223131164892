var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{ref:_vm.pinnedMessage && _vm.message.id == _vm.pinnedMessage.id ? 'refPinnedMessage' : '',staticClass:"d-flex position-relative",on:{"click":function($event){return _vm.toggleMenu($event)}}},[(_vm.pinnedMessage && _vm.message.id == _vm.pinnedMessage.id)?_c('div',{staticClass:"pinned-message"},[_c('v-icon',[_vm._v("mdi-pin-outline")])],1):_vm._e(),(_vm.isTypeStory)?_c('div',{class:['MessageItem-wrap', {right: _vm.own}, 'up']},[_c('div',{staticClass:"profile-user-icon"},[(!_vm.own)?[_c('router-link',{attrs:{"to":{
                        name: 'otherUser.profile.viewSlug',
                        params: Object.assign({}, {slug: _vm.message.sender_slug,
                            from: _vm.$route.name},
                            ( _vm.$route.name === 'user.own.channel' 
                                ? { chat_id: _vm.$route.params.chat_id } 
                                : { id: _vm.$route.params.id }
                            )),
                    }}},[_c('profile-image',{attrs:{"src":_vm.message.profile_image,"username":_vm.message.sender_name}})],1)]:[_c('profile-image',{attrs:{"src":_vm.message.profile_image,"username":_vm.message.sender_name}})]],2),_c('div',{staticClass:"pa-0 ma-0 mb-4 mr-2"},[(_vm.isBroadcastChat)?_c('div',{class:[
                'MessageItem-cloud message mb-1', _vm.own ? 'float-right' : 'float-left',
                {'my-message': _vm.own} ]},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.message.sender_name))])]):_vm._e(),_c('story-view-message',{attrs:{"story":_vm.message.story,"is-own":_vm.own},on:{"reload-chat":_vm.handleReloadChat}})],1)]):(_vm.isTypeAudio)?_c('div',{class:['MessageItem-wrap', {right: _vm.own}]},[_c('div',{staticClass:"profile-user-icon"},[(!_vm.own)?[_c('router-link',{attrs:{"to":{
                        name: 'otherUser.profile.viewSlug',
                        params: Object.assign({}, {slug: _vm.message.sender_slug,
                            from: _vm.$route.name},
                            ( _vm.$route.name === 'user.own.channel' 
                                ? { chat_id: _vm.$route.params.chat_id } 
                                : { id: _vm.$route.params.id }
                            )),
                    }}},[_c('profile-image',{attrs:{"src":_vm.message.profile_image,"username":_vm.message.sender_name}})],1)]:[_c('profile-image',{attrs:{"src":_vm.message.profile_image,"username":_vm.message.sender_name}})]],2),_c('audio-player',{attrs:{"audio-src":_vm.message.audio_file_url,"is-own":_vm.own}})],1):_c('div',{class:['MessageItem-wrap', {'right': _vm.own}]},[_c('div',{staticClass:"profile-user-icon"},[(!_vm.own)?[_c('router-link',{attrs:{"to":{
                        name: 'otherUser.profile.viewSlug',
                        params: Object.assign({}, {slug: _vm.message.sender_slug,
                            from: _vm.$route.name},
                            ( _vm.$route.name === 'user.own.channel' 
                                ? { chat_id: _vm.$route.params.chat_id } 
                                : { id: _vm.$route.params.id }
                            )),
                    }}},[_c('profile-image',{attrs:{"src":_vm.message.profile_image,"username":_vm.message.sender_name}})],1)]:[_c('profile-image',{attrs:{"src":_vm.message.profile_image,"username":_vm.message.sender_name}})]],2),_c('div',{class:[
            'MessageItem-cloud message',
            {'my-message mr-2': _vm.own, 'money': _vm.isTypeUserTip || _vm.isTypeCreatorTip} ]},[(_vm.isBroadcastChat)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.message.sender_name))]):_vm._e(),(_vm.title)?_c('div',{staticClass:"mb-1 MessageItem-request"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.amount)?_c('div',{staticClass:"MessageItem-amount"},[_vm._v(_vm._s(_vm.amount))]):_vm._e(),(_vm.formatText)?_c('div',{staticClass:"MessageItem-text text",class:{'text-center': _vm.isTypeUserTip},domProps:{"innerHTML":_vm._s(_vm.formatText)}}):_vm._e()])]),_c('div',{staticClass:"MessageItem-date date pl-2 pr-2",class:['MessageItem-wrap', {right: _vm.own}]},[_vm._v(" "+_vm._s(_vm.date)+" "),(_vm.message.is_read && _vm.own)?_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(this.$t("messages.message_seen"))+" ")]):_vm._e()]),(_vm.lastMessage)?_c('div',{ref:"lastMessage"}):_vm._e(),_c('v-spacer',{attrs:{"order":_vm.own ? 1 : 2}}),_c('v-menu',{attrs:{"position-x":_vm.menuPosition.x,"position-y":_vm.menuPosition.y,"offset-y":"","min-width":"100px","content-class":"message-pin"},on:{"click:outside":_vm.closeMenu},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',[_c('v-list-item',{staticClass:"message-pin-list",on:{"click":_vm.pinMessage}},[_c('v-list-item-title',{staticClass:"message-title"},[_vm._v(" "+_vm._s(_vm.$t("messages.pin"))+" "),_c('v-icon',[_vm._v("mdi-pin-outline")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }