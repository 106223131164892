<template>
    <v-row v-if="isTyping" class="typing-row">
        <v-col cols="1" class="pa-0 ma-0">
            <div class="chat-image-user not-me" :style="`background-image: url(${senderImg})`"></div>
        </v-col>
        <v-col>
        	<div class="snippet" data-title="dot-typing">
				<div class="stage">
					<div class="dot-typing"></div>
				</div>
          	</div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "TypingMessage",
    props: {
        senderImg: {
            type: String
        },
        isTyping: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.chat-image-user{
    &.not-me{
        width: 29px;
        height: 29px;
        background-size: cover;
        border-radius: 50%;
    }
}

.typing {
    display: flex;
    align-items: center;
    justify-content: center;

    &-row {
        align-items: center;
        justify-content: flex-start;
    }

    &-wrapper {
        display: flex;
        align-items: center;
        flex-grow: initial;
        justify-content: center;
        width: 41px;
        height: 26px;
    }
}

.snippet {
    width: 64px;
    float: left;
    position: relative;
    background-color: rgb(49,49,49);
    margin: 24px 0;
    border-radius: 24px;
}

.stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 12px 5px;
}

.dot-typing {
    position: relative;
    left: -9999px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #FC6B16;
    color: #FC6B16;
    box-shadow: 9984px 0 0 0 #FC6B16, 9999px 0 0 0 #FC6B16, 10014px 0 0 0 #FC6B16;
    animation: dot-typing 2s infinite linear;
}

@keyframes dot-typing {
    0% {
        box-shadow: 9984px 0 0 0 #FC6B16, 9999px 0 0 0 #FC6B16, 10014px 0 0 0 #FC6B16;
    }
    8.33% {
        box-shadow: 9984px 0px 0 0 #FC6B16, 9999px 0 0 0 #FC6B16, 10014px 0 0 0 #FC6B16;
    }
    16.667% {
        box-shadow: 9984px 0px 0 0 #FC6B16, 9999px 0 0 0 #FC6B16, 10014px 0 0 0 #FC6B16;
    }
    24.99% {
        box-shadow: 9984px -6px 0 0 #FC6B16, 9999px 0px 0 0 #FC6B16, 10014px 0 0 0 #FC6B16;
    }
    33.33% {
        box-shadow: 9984px 0 0 0 #FC6B16, 9999px 0 0 0 #FC6B16, 10014px 0 0 0 #FC6B16;
    }
    41.66% {
        box-shadow: 9984px 0 0 0 #FC6B16, 9999px 0 0 0 #FC6B16, 10014px 0px 0 0 #FC6B16;
    }
    49.987% {
        box-shadow: 9984px 0 0 0 #FC6B16, 9999px 0 0 0 #FC6B16, 10014px 0 0 0 #FC6B16;
    }
    58.317% {
        box-shadow: 9984px 0 0 0 #FC6B16, 9999px -6px 0 0 #FC6B16, 10014px 0 0 0 #FC6B16;
    }
    66.66% {
        box-shadow: 9984px 0 0 0 #FC6B16, 9999px 0 0 0 #FC6B16, 10014px 0 0 0 #FC6B16;
    }
    74.97% {
        box-shadow: 9984px 0 0 0 #FC6B16, 9999px 0 0 0 #FC6B16, 10014px 0 0 0 #FC6B16;
    }
    83.30% {
        box-shadow: 9984px 0 0 0 #FC6B16, 9999px 0 0 0 #FC6B16, 10014px 0 0 0 #FC6B16;
    }
    91.637% {
        box-shadow: 9984px 0 0 0 #FC6B16, 9999px 0 0 0 #FC6B16, 10014px -6px 0 0 #FC6B16;
    }
    100% {
        box-shadow: 9984px 0 0 0 #FC6B16, 9999px 0 0 0 #FC6B16, 10014px 0 0 0 #FC6B16;
    }

}


</style>
