<template>
	<svg :width="width" :height="height" viewBox="0 0 131 124" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="7" width="124" height="124" rx="62" fill="url(#paint0_linear_1_6)"/>
        <path d="M109.047 68.3362L42.6278 100.341C42.1418 100.575 41.5943 100.648 41.0642 100.55C40.534 100.453 40.0486 100.189 39.6777 99.7981C39.2861 99.4278 39.0226 98.9425 38.9252 98.4124C38.8278 97.8824 38.9017 97.3351 39.1361 96.8498L54.685 64.5691C55.146 63.5802 55.3723 62.4981 55.346 61.4072C55.3197 60.3164 55.0416 59.2464 54.5334 58.2808L37.4152 25.2219C37.149 24.7589 37.0443 24.2205 37.1176 23.6915C37.1908 23.1625 37.438 22.6728 37.82 22.2996C38.1935 21.9179 38.6831 21.6711 39.212 21.5978C39.741 21.5246 40.2792 21.629 40.7424 21.8948L108.788 57.1348C109.849 57.6653 110.746 58.4756 111.38 59.4782C112.015 60.4809 112.364 61.6379 112.389 62.8242C112.439 63.9705 112.149 65.106 111.554 66.0872C110.959 67.0683 110.087 67.851 109.047 68.3362Z" fill="white"/>
        <defs>
            <linearGradient id="paint0_linear_1_6" x1="69" y1="-39.5" x2="70.1615" y2="124" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFC107"/>
                <stop offset="0.15" stop-color="#FFAB40"/>
                <stop offset="0.4" stop-color="#FF9100"/>
                <stop offset="1" stop-color="#FF3D00"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
	name: "SendButtonIcon",
	props: {
		height: {
			type: Number,
			default: 48
		},
		width: {
			type: Number,
			default: 48
		}
  	}
}
</script>
