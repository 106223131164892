<template>
    <div
        class="image-container"
        :class="getStoryCircle"
        :style=" {width: `${sizeBorder}px`, height: `${sizeBorder}px`, padding: `${border}px`}"
    >
        <img
            v-if="imageSrc"
            :src="imageSrc"
            :alt="imageSrc"
            class="profile-image"
            :style="{width: `${size}px`, height: `${size}px`, border: `${border}px solid black`}"
            @click="handleClickProfile"
        />
        <div
            @click="handleClickProfile"
            class="profile-image--default"
            :style="{'font-size': `${fontSize}px`, width: `${size}px`, height: `${size}px`, border: `${border}px solid ${isExistStory ? 'black' : 'white'}`}"
            v-else
        >
            {{firstLetters}}
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "ProfileImage",
    components: {
    },
    data: () => ({
        isShowStory: false,
        isEditStory: false,
    }),
    props: {
        size: {
            type: [String, Number],
            default: 134
        },
        img: {
            //type: [String, undefined],
            required: true
        },
        isExistStory: {
            type: Boolean,
            default: true
        },
        hasNotWatchedStories: {
            type: Boolean,
            default: false
        },
        border: {
            type: [String, Number],
            default: 6
        },
        id: {
            type: [Number, String],
            default: ''
        },
        includeStories: {
            type: Boolean,
            default: true
        },
        editable: {
            type: Boolean,
            default: false
        },
        username: {
            type: String,
            required: true,
        },
        fontSize: {
            type: Number,
            default: 42,
        },
    },
    computed: {
        firstLetters() {
            return this.username.slice(0, 2).toUpperCase()
        },
        sizeBorder() {
            return this.size - 0 + (this.border * 2);
        },
        imageSrc() {
            return (typeof this.img !== 'string') ? false : this.img;
        },
        getStoryCircle() {
            if (this.isExistStory) {
                if (this.hasNotWatchedStories) {
                    return {
                        'has-not-watched-stories': true,
                    }
                }

                return {
                    'story-exist': true,
                }
            }

            return  {}
        }
    },
    methods: {
        showStory() {
            if (this.includeStories) {
                this.isShowStory = true;
                if ('id' in this.$store.state.story.currentStory || this.$store.state.story.currentStory.id !== this.id) {
                    this.getStory({id: this.id})
                }
            }
        },
        handleCloseEditStory() {
            this.isEditStory = false
        },
        handleClickProfile() {
            if (this.editable) {
                this.isEditStory = true
            } else if (this.isExistStory) {
                this.showStory()
            }
            this.$emit('click')
        },
        ...mapActions(['getStory']),
    },
    mounted() {

    },
    /*beforeDestroy() {
        this.$store.commit('setStory')
    }*/
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.profile-image {
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: whitesmoke;
    object-fit: cover;

    &--default {
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        background-color: #141414;
        color: #FE4811;
    }
}

.image-container {
    border-radius: 50%;
    padding-left: 4px;
    padding-top: 4px;
}

.story-exist {
    background: $grey-background;
}

.has-not-watched-stories {
    background: $accent-background;
}
</style>
