<template>
    <div class="overlay">
        <v-container fluid class="h-full">
            
            <div class="dashed-lines-left">
            </div>
            
            <div class="dashed-lines-right">
            </div>
            
            <v-row align="center" justify="center" class="h-full">
                <v-col>
                    <div class="dialog">

                        <v-row class="pb-6">
                            <v-col @click.stop="$emit('left')" class="yes col-4">
                                <div class="circle-style">
                                    <span class="choose yes">
                                        <v-icon size="60">mdi-chevron-left</v-icon>
                                        {{ $t('dialogs.tap_back') }}
                                    </span>
                                </div>
                            </v-col>

                            <v-col @click.stop="$emit('center')" class="center col-4">
                                <div class="circle-style">
                                    <span class="choose center">
                                        <pause-icon></pause-icon>
                                        <!-- <v-icon size="60">mdi-pause</v-icon> -->
                                        {{ $t('dialogs.pause') }}
                                    </span>
                                </div>
                            </v-col>

                            <v-col @click.stop="$emit('right')" class="no col-4">
                                <div class="circle-style">
                                    <span  class="choose no">
                                        <v-icon size="60">mdi-chevron-right</v-icon>
                                        {{ $t('dialogs.tap_next') }}
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                        <slot name="afterButtons"></slot>
                    </div>
                </v-col>
            </v-row>

        </v-container>

    </div>
</template>

<script>
import PauseIcon from "@/assets/images/icons/pause.svg?inline";
export default {
    name: "StoryGuide",
    components: {
        PauseIcon,
    },
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.dashed-lines-left:after { 
    content:"";
    position: absolute;
    z-index: -1;
    top: -50px;
    bottom: -50px;
    left: 30%;
    border-left: 2px dashed #FFFFFF;
}

.dashed-lines-right:after { 
    content:"";
    position: absolute;
    z-index: -1;
    top: -50px;
    bottom: -50px;
    right: 30%;
    border-left: 2px dashed #FFFFFF;
}

.yes{
    justify-content: center;
    display: flex;
    align-items: center;
}

.no { 
    justify-content: center;
    display: flex;
    align-items: center;
 }

 .center {
    justify-content: center;
    display: flex;
    align-items: center;
 }

.choose {
    // border: 1px solid white;
    // border-radius: 13px;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    color: #FFFFFF;
}

.choose i {
    color: #000000;
    margin-top: 5px;
} 

.no .circle-style, .yes .circle-style, .center .circle-style { 
    background-color: #FFFFFF; 
    height: 70px;
    width: 70px;
    border-radius: 100%;
}

.no .circle-style:before, .yes .circle-style:before, .center .circle-style:before { 
    content: " ";
    height: 70px;
    width: 70px;
    border-radius: 100%;
    margin: 0 auto;
    transition: all 0.3s;
    animation: mymove 2s infinite;
    position: absolute;
    background-color: #FFFFFF; 
}

@-webkit-keyframes mymove {
  50%   {
    transform: scale(2);
    opacity: 0
  }
  100%   {
    transform: scale(2);
    opacity: 0
  }
}

.choose:active {
    // border: 1px solid white;
    // border-radius: 13px;
    // background: rgba(255,255,255,0.3);
}
.link {
    text-decoration: none;
    color: white;
    font-size: 1.3em;
}

.overlay {
    position: fixed;
    background: rgba(52, 52, 65, 0.88);
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.dialog {
    // background: $accent-background;
    // min-height: 140px;
    // border-radius: 14px;
}
.title{
    font-size: 1.3em;
}
</style>
