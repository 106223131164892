<template>
    <div class="follow-button" :class="dense ? 'follow-button--dense' : ''" @click.once="onClick" :key="buttonKey">
        <app-icon v-if="isFollowing" icon="check" color="#FE4811" size="16"></app-icon>
        <div class="plus"  v-else>+</div>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "FollowButton",
    props: {
        userId: {
            type: [Number, String],
            required: true,
        },
        isFollowing: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
        }
    },
    data() {
        return {
            followProcess: false,
            buttonKey: 1,
        }
    },
    methods: {
        ...mapActions([
            'follow',
            'unfollow',
        ]),

        async onClick () {
            if (this.isFollowing) {
                await this.unfollow(this.userId)

            } else {
                await this.follow(this.userId)
            }
            this.buttonKey++
            this.$emit('click');
        }
    }
}
</script>

<style scoped lang="scss">
$size-button: 34px;

.follow-button {
    height: $size-button;
    width: $size-button;
    background: white;
    text-align: center;
    align-items: center;
    text-decoration: none;
    color: #FE4811;
    border-radius: 50%;
    border: 3px solid black;
    padding-top: 0;
    position: absolute;
    right: 0px;
    bottom: 0px;
    font-size: 1.3em;
    font-weight: bold;
    cursor: pointer;

    &.follow-button--dense {
        font-size: 1em;
        width: 20px;
        height: 20px;

        & .icon {
            font-size: 10px;
            line-height: 14px !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .plus {
            font-size: 1em;
            line-height: 16px;
        }
    }

    .plus {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        line-height: 29px;
    }
}


.story.follow-button {
    width: 20px;
    height: 20px;
    right: -5px;
    bottom: 0px;

    & .icon {
        font-size: 12px;
        line-height: 16px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .plus {
        font-size: 20px;
        line-height: 16px;
    }
}
</style>
