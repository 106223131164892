<template>
    <div :class="{line: true, active: active}">
        <span :class="{'active-line':  loaded}" :style="[animationDuration]"></span>
    </div>
</template>

<script>
export default {
    name: "CountStores",
    props: {
        active: {
            type: Boolean,
            default: false
        },
        loaded: {
            type: Boolean,
            default: false,
        },
        time: {
            type: [String, Number],
            default: 7
        },
    },
    watch: {
        loaded() {
            // fixes a bug - video repeat in the progress bar. Fix later
            axios.get('/api/echo')
        }
    },
    computed: {
        animationDuration() {
            return {'animation-duration': `${this.time}s`};
        },
    },

}
</script>

<style scoped lang="scss">
$not-active: rgba(255, 255, 255, 0.4);
$active: rgba(255, 255, 255, 1);
.line {
    background: $not-active;
    height: 2px;
    width: 100%;
    border-radius: 2px;
}

.active {
    background: $not-active;

}

.active-line {
    height: 100%;
    width: 0;
    display: block;
    background: white;
    animation-name: line;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes line {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}
</style>
