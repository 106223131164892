<template>
    <div :class="{'story-container': true,  unavailable:isAvailable}">
        <v-container class="fill-height relative">
            <v-row align="center" class="fill-height unavailable-container" justify="center">
                <v-col class="text-center relative">
                    <v-row justify="center" class="story-heart-icon">
                        <heart-lock/>
                    </v-row>
                    <span class="story-heart-price">{{ price }}{{ currency }}</span>
                    <v-row justify="center">
                        <gradient-button @click="handleUnlockStory" class="ma-2 button-story-unlock enable-events">{{ $t('dialogs.unlock') }}</gradient-button>
                    </v-row>
                    <v-row v-if="user" justify="center">
                        {{ $t('dialogs.or') }}
                    </v-row>
                    <v-row v-if="user" justify="center">
                        <gradient-button
                            class="ma-2 button-story-subscribe display-block"
                            @click="subscribeMethod"
                        >
                            <div class="display-block">
                                <span class="display-block">{{ $t('dialogs.subscribe') }}</span>
                                <small class="display-block">{{ user.subscribePrice }}{{ currency }}/ {{ $t('dialogs.month') }}</small>
                            </div>
                        </gradient-button>
                    </v-row>
                </v-col>
            </v-row>
            <subscribe-dialog
                v-if="user && subscribeInfo"
                :price="user.subscribePrice"
                :subscription="subscription"
                :subscription-bundles="subscription.bundles"
                @subscribe="handleSubscribe"
                @close="closeSubscribeInfo"
            />
            <unlock-content-dialog
                v-if="unlockDialog"
                :price="price"
                @close="handleCloseUnlockDialog"
                @unlock="doUnlock"
            />
        </v-container>
    </div>
</template>

<script>
import GradientButton from "@/components/app/button/GradientButton";
import HeartLock from "@/assets/images/icons/heart-lock.svg?inline";
import SubscribeDialog from "@/components/app/dialogs/SubscribeDialog";
import UnlockContentDialog from "@/components/app/dialogs/UnlockContentDialog"
import {CURRENCY_SYMBOL} from "@/configs/constants";
import {mapActions} from "vuex";

export default {
    name: "Unavailable",
    components: {
        GradientButton,
        HeartLock,
        SubscribeDialog,
        UnlockContentDialog,
    },
    data: () => ({
        currency: CURRENCY_SYMBOL,
        subscribeInfo: false,
        unlockDialog: false,
        user: false
    }),
    props: {
        isAvailable: {
            type: Boolean,
            default: false
        },
        price: {
            type: [String, Number],
            default: '0'
        },
        subscription: {
            type: Object,
            default: null
        },
        userId: {
            type: Number,
        }
    },
    methods: {
        ...mapActions([
            'getUserById',
            'subscribe',
        ]),
        subscribeMethod() {
            this.subscribeInfo = true
        },
        closeSubscribeInfo() {
            this.subscribeInfo = false
        },
        async handleSubscribe() {
            this.subscribeInfo = false
            await this.subscribe(this.userId).then(res => {
                if (typeof res.redirectUrl !== 'undefined') {
                    this.$window.location = res.redirectUrl
                }
                if (typeof res.success !== 'undefined') {
                    this.showSnackBar(res.success)
                }
            })
        },
        async handleUnlockStory() {
            this.unlockDialog = true
        },
        doUnlock() {
            this.unlockDialog = false
            this.$emit('unlock-story')
        },
        handleCloseUnlockDialog() {
            this.unlockDialog = false
        },
    },
    mounted() {
        if (!this.user) {
            this.getUserById(this.userId).then((res) => {
                this.user = res
            })
        }
    }
}
</script>

<style scoped>
.story-container.unavailable {
    position: absolute;
    top: 0px;
    bottom: 0px;
    z-index: 4;
    left: calc(50% - 95px);
    right: calc(50% - 95px);
    width: 190px;
}

.story-heart-price {
    position: relative;
    bottom: 74px;
    color: #FE4811;
    font-size: 1.6em;
}

.unavailable-container {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    width:190px;
    margin: 0 auto;
}
.button-story-unlock, .button-story-subscribe {
    width: 190px;
}

.display-block {
    display: block
}

.story-container small {
    font-size: 65%;
}

.story-heart-icon svg {
    width: 114px;
    height: 152px;
}
</style>
