<template>
    <div class="own-story-menu" v-touch="{down: ()=>{}}">
        <story-layout
            v-if="fullscreenStory"
            :story="fullscreenStory"
            :src="fullscreenStory.overlay_src"
            :overlay="fullscreenStory.overlay_src"
            @touchmove.native.stop.prevent
            :type="fullscreenStory.is_video ? 'video' : 'image'"
        >

        </story-layout>
        <div class="lighter-back">
            <app-icon v-if="fullscreenStory" class="close-fullscreen" icon="close" @click.once="handleClose"></app-icon>
            <app-icon v-else class="close" icon="close" @click="$emit('close')"></app-icon>
            <div class="images-container pt-10 pl-0 pb-1"
                 :style="`transform: translateX(calc(50vw - ${(currentCarousel)*88+51}px));`" v-touch="{
                right: ()=> handleMoveRight(),
                left: ()=> handleMoveLeft(),
            }">
                <div v-for="(item, i) in userStories" :key="i">
                    <div v-if="item.is_processing"
                         class="story-image my-3 mx-3 d-flex justify-center align-center">
                        <v-progress-circular model-value="20"
                                             indeterminate
                                             color="#ff9100"
                                             :size="33"/>
                    </div>
                    <template v-else>
<!--                        <div>{{item.overlay_image_src}}</div>-->
<!--                        <div>{{item.mp4_video_src}}</div>-->
<!--                        <div>{{item.webm_video_src}}</div>-->
                        <video v-if="item.is_video"
                               loop
                               playsinline
                               :poster="item.overlay_image_src"
                               :muted="i!==currentCarousel"
                               @click="setActive(i)"
                               @dblclick="showFullscreen(item)"
                               :class="['story-image ml-3 mr-3', {active: i===currentCarousel}]"
                        >
                            <source :src="item.mp4_video_src" :type="mimetypeMp4"/>
                            <source :src="item.webm_video_src" :type="mimetypeWebm"/>
                        </video>
                        <img v-else
                             :src="item.overlay_image_src"
                             @click="setActive(i)"
                             @dblclick="showFullscreen(item)"
                             alt="story image"
                             :class="{'story-image ml-3 mr-3': true, active: i===currentCarousel}"
                        />
                    </template>
                </div>

            </div>
            <div class="text-center chevron">
                <v-icon size="34">mdi-chevron-up</v-icon>
            </div>
        </div>
        <div class="darker-back fill-height ">
           <template>
               <v-container class="pl-10 pr-10">
                   <v-row v-if="currentStory" align="center">
                       <v-col cols="2" class="text-center "> <!-- TODO: make icon -->
                           <eye/>
                           <p v-if="typeof currentStory !== 'undefined' && currentStory.view_count" class="count-viewers">
                               {{ currentStory.view_count }}
                           </p>
                           <p v-else class="count-viewers">
                               0
                           </p>
                       </v-col>
                       <v-spacer/>
                       <v-col cols="2">
                           <app-icon icon="write" size="24" @click="handleEditStory"></app-icon>
                       </v-col>
                       <v-col cols="2">
                           <app-icon icon="delete" size="24" @click="isShowDeleteDialog= true"></app-icon>
                       </v-col>
                   </v-row>
                   <v-row v-if="user.verified" align="center">
                       <v-expansion-panels>
                           <expand-block>
                               <template v-slot:header>
                                   <span class="pa-4 title-panel">{{ $t('dialogs.purchased') }}</span>
                               </template>
                               <template v-slot:rightHeader><span class="title-price">{{ purchased_total }} {{
                                       currency
                                   }}</span></template>

                               <div v-for="(item, index) in purchased" :key="index"
                                    class="body-text mt-2 mb-2 pl-2 pr-2 pb-2">
                                   <b>{{ item.username }}</b> {{ $t('dialogs.gives_you') }} <b>{{ item.price }}{{
                                       currency
                                   }}</b>.
                               </div>
                           </expand-block>

                       </v-expansion-panels>
                   </v-row>
                   <v-row v-if="user.verified" align="center" class="mt-5">
                       <v-expansion-panels>
                           <expand-block>
                               <template v-slot:header>
                                   <span class="pa-4 title-panel">{{ $t('dialogs.tips') }}</span>
                               </template>
                               <template v-slot:rightHeader><span class="title-price">{{ tips_total }} {{
                                       currency
                                   }}</span></template>

                               <div v-for="(item, index) in tips" :key="index"
                                    class="body-text pl-2 pr-2 pb-2">
                                   <b>{{ item.username }}</b> {{ $t('dialogs.gives_you') }} <b>{{ item.price }}{{
                                       currency
                                   }}</b>.
                                   <div class="hint" v-if="item.title">
                                       <i>“{{ item.title }}”</i>
                                   </div>
                               </div>
                           </expand-block>

                       </v-expansion-panels>
                   </v-row>
                   <v-row v-if="user.verified" justify="center" align="center" class="mt-10">
                       <v-col class="TotalBlock text-center pa-0">
                           <gradient-button block class=" body-total ma-0"><span
                               class="total-text">{{ $t('dialogs.total') }}</span>
                               <v-spacer/>
                               <b class="total-small">{{ total }} {{ currency }}</b></gradient-button>
                       </v-col>
                   </v-row>
               </v-container>
               <app-transition>
                   <yes-no-dialog v-if="isShowDeleteDialog" @yes="handleDeleteStory" @no="isShowDeleteDialog = false">
                       {{ $t('dialogs.are_you_sure') }}
                       <template v-if="user.verified" v-slot:afterButtons>
                           <div class="hint-dialog pa-5 pt-0">
                               <i style="color: rgba(255,255,255,.7)">{{ $t('dialogs.delete_story_hint') }}</i>
                           </div>
                       </template>
                   </yes-no-dialog>
               </app-transition>
               <app-transition>
                   <Dialog
                       v-if="currentStory && isShowModifyDialog"
                       @close="isShowModifyDialog = false"
                       :scrollable="true"
                   >
                       <div class="title-dialog text-center"><b>{{ $t('dialogs.modify_content') }}</b></div>
                       <v-container class="pl-0 pr-0 pb-0">
                           <div class="StoryPrice-wrapper">
                               <div class="StoryPrice-price">
                                   <v-text-field
                                       filled
                                       outlined
                                       dense
                                       solo
                                       solo-inverted
                                       background-color="rgba(237,237,237,.19)"
                                       color="white"
                                       placeholder="0-100"
                                       :suffix="currency"
                                       v-model="currentStory.price"
                                       :error-messages="errors.price"
                                       white-text
                                       text-center
                                   ></v-text-field>
                               </div>
                               <div class="StoryPrice-free">
                                   <v-radio-group v-model="free">
                                       <v-radio value="free" :label="$t('dialogs.free')" color="white"></v-radio>
                                   </v-radio-group>
                               </div>
                           </div>
                           <v-row>
                               <v-col>
                                   <autocomplete-hashtags filled
                                                          hide-details
                                                          outlined
                                                          dense
                                                          solo
                                                          solo-inverted
                                                          v-model="currentStory.text"
                                                          rows="3"
                                                          no-resize
                                                          :placeholder="$t('dialogs.your_message')"
                                                          :maxlength="storyDescriptionLength"
                                                          class=""></autocomplete-hashtags>
                               </v-col>
                           </v-row>
                           <v-row class="mt-10 mb-0">
                               <v-col>
                                   <gradient-button
                                       style="color: white; background: transparent;"
                                       block
                                       outlined
                                       class="ma-0"
                                       @click="handleSendEditedStory"
                                   >{{ $t('dialogs.send') }}
                                   </gradient-button>
                               </v-col>
                           </v-row>
                       </v-container>
                   </Dialog>
               </app-transition>
           </template>
        </div>
    </div>
</template>

<script>
import ExpandBlock from "@/components/app/posting/ExpandBlock";
import GradientButton from "@/components/app/button/GradientButton";
import YesNoDialog from "@/components/app/setting/YesNoDialog";
import StoryLayout from "@/layouts/app/postingLayout/StoryLayout";
import Dialog from "@/components/app/form/Dialog";
import Eye from "@/assets/images/icons/eye.svg?inline";
import AutocompleteHashtags from "@/components/app/form/AutocompleteHashtags";
import {CURRENCY_SYMBOL} from "@/configs/constants";
import {mapActions, mapMutations, mapState} from "vuex";
import {stripHTML} from "@/helpers/functions";
import {MAX_STORY_DESCRIPTION_LENGTH} from "@/configs/constants";
import {
    VIDEO_MP4_EXTENSION,
    VIDEO_MP4_MIMETYPE,
    VIDEO_WEBM_EXTENSION,
    VIDEO_WEBM_MIMETYPE
} from "@/configs/constants";

export default {
    name: "OwnStoryMenu",
    components: {
        Dialog,
        GradientButton,
        ExpandBlock,
        YesNoDialog,
        Eye,
        AutocompleteHashtags,
        StoryLayout,
    },
    data: () => ({
        modifyDialog: {
            tip: '5',
            message: 'Hello Everyone That’s me with a new coat demo text.',
            free: null,
        },
        isShowDeleteDialog: false,
        isShowModifyDialog: false,
        fullscreenStory: false,
        currency: CURRENCY_SYMBOL,
        currentCarousel: 0,
        free: null,
        storyDescriptionLength: MAX_STORY_DESCRIPTION_LENGTH,
        errors: {},
    }),
    computed: {
        ...mapState({
            userStories: state => state.story.userStories.stories,
            user: state => state.user.user.data,
            purchased: state => state.story.profileStory.purchased,
            tips: state => state.story.profileStory.tips,
            purchased_total: state => state.story.profileStory.purchased_total,
            tips_total: state => state.story.profileStory.tips_total,
            total: state => state.story.profileStory.total,
        }),
        currentStory() {
            if (typeof this.userStories !== 'undefined') {
                return this.userStories[this.currentCarousel]
            }
            return false
        },
        mimetypeMp4() {
            return VIDEO_MP4_MIMETYPE
        },
        mimetypeWebm() {
            return VIDEO_WEBM_MIMETYPE
        },
    },
    props: {
        activeCarousel: {
            type: Number,
            default: 0
        }
    },
    watch: {
        free(value) {
            if (typeof this.userStories !== 'undefined' && this.userStories.length !== 0 && value === 'free') {
                this.currentStory.price = 0
                this.currentStory.free = 'free'
            }
        },
        'currentStory.price'(value) {
            if (value && typeof this.userStories !== 'undefined' && this.userStories.length !== 0) {
                const float = parseFloat(value)
                const price = isNaN(float) ? 0 : float
                this.userStories[this.currentCarousel].price = price
                const free = price === 0 ? 'free' : null
                this.userStories[this.currentCarousel].free = free
                this.currentStory.free = free
                this.free = free

            }
        },
        currentStory(value) {
            if (value) {
                this.getStatistics(value.id)
            }
        },
        'currentStory.text'(value) {
            this.currentStory.text = stripHTML(value)
        },
    },
    methods: {
        ...mapActions([
            'getUserStories',
            'sendEditedStory',
            'deleteStory',
            'getStatistics',
            'isVerifyUser',
        ]),
        ...mapMutations([
            'showSnackBar',
            'hideBottomBar',
            'showBottomBar',
        ]),
        setActive(i) {
            this.currentCarousel = i;
        },
        handleMoveRight() {
            this.currentCarousel > 0 ? this.currentCarousel-- : ''
        },
        handleMoveLeft() {
            this.currentCarousel < (this.userStories.length - 1) ? this.currentCarousel++ : ''
        },
        handleEditStory() {
            this.isShowModifyDialog = true
            if (!this.currentStory.price) {
                this.currentStory.free = 'free'
                this.free = 'free'
            }
            this.modifyDialog = this.currentStory

        },
        async handleSendEditedStory() {
            this.errors = {}
            let model = this.currentStory
            if (model.price !== 0 && model.price !== null) {
                const verify = await this.isVerifyUser()
                if (!verify) {
                    return
                }
            }

            model.price = model.price !== 0 ? model.price : null

            this.sendEditedStory({model}).then((res) => {
                this.showSnackBar(res.data.message)
                this.isShowModifyDialog = false
            }).catch((e) => {
                this.errors = e.response.data.err_msg
                if (typeof e.response.data.err_msg === 'undefined') {
                    const message = e.response.data.message === '' ? e.response.statusText : e.response.data.message
                    this.showSnackBar(message)
                    this.errors = e.response.data.errors
                }
            })
            this.getUserStories()
        },
        async handleDeleteStory() {
            const model = this.currentStory
            this.setActive(0)
            await this.deleteStory({model})
            await this.getUserStories()
            this.isShowDeleteDialog = false
        },
        showFullscreen(item) {
            this.fullscreenStory = item
            this.hideBottomBar()
        },
        handleClose() {
            if (this.fullscreenStory) {
                this.fullscreenStory = false
            } else {
                this.$emit('close')
            }
            this.showBottomBar()
        },
        videoMp4(url) {
            return `${url}.${VIDEO_MP4_EXTENSION}`
        },
        videoWebm(url) {
            return `${url}.${VIDEO_WEBM_EXTENSION}`
        },
    },
    created() {
        this.currentCarousel = this.activeCarousel
        this.getUserStories()
    }
}
</script>

<style scoped>
.title-dialog {
    font-size: 1.3em;
}

.hint-dialog {
    color: rgba(255, 255, 255, .7);
    font-size: 0.8em;
    text-align: center;
    text-justify: auto;
}

.total-small {
    font-size: 100%;

}

.total-text {
    font-size: 1.1em;
    line-height: 3.5em
}

.body-total {


}

.title-panel {
    line-height: 2em;
    font-size: 1.1em;
}

.title-price {
    line-height: 2.3em;
    font-size: 1.4em;
    color: #FE4811;
}

.count-viewers {
    margin-top: -5px;
    font-size: 90%;
}

.chevron {
    width: 100vw;
}

.lighter-back {
    background-color: #1A1A1A;
}

.own-story-menu {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
}

.story-image {
    max-height: 150px;
    display: inline-block;
    float: left;
    width: 64px;
    transition: 0.2s;
}

.images-container {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 1000vw;
    min-height: 185px;
    transition: .2s;
}

.active {
    max-height: 200px;
    width: 78px;
}

.close, .close-fullscreen {
    position: relative;
    top: 1.6em;
    left: 1.1em;
    z-index: 100;
}

.close-fullscreen {
    z-index: 101;
}

.darker-back {
    background-color: black;
    overflow: auto;
    height: calc(100vh - 310px);
}

/deep/ .AutocompleteHashtags-dropdown {
    background-color: #FC882D;
    overflow: hidden;
    border: solid 1px #fff;
    border-top: none;
    left: -1px;
    width: calc(100% + 2px);
}

/deep/ .AutocompleteHashtags-wrapper {
    border: 1px solid rgba(237, 237, 237, .19);
    box-shadow: 0px 3px 10px -2px rgba(85, 85, 85, 0.08), 0px 2px 20px 0px rgba(85, 85, 85, 0.06), 0px 1px 30px 0px rgba(85, 85, 85, 0.03);
    background-color: rgba(237, 237, 237, .19);
    padding: 0 17px;
    border-radius: 6px;
    transition-duration: 0.3s;
    transition-property: color, border;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    position: relative;
}

/deep/ .AutocompleteHashtags-wrapper:hover,
/deep/ .AutocompleteHashtags-wrapper.dropdown-open {
    border: 1px solid #fff;
}

/deep/ .AutocompleteHashtags-wrapper:focus {
    border: 2px solid #fff;
}

/deep/ .AutocompleteHashtags-item--username span {
    color: black;
}

.StoryPrice-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.StoryPrice-price {
    width: 50%;
}

.TotalBlock {
    height: 128px;
}

.v-input--selection-controls {
    margin-top: 0;
}
</style>
