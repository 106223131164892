<template>
    <div class="StoryViewMessage" :class="{
        'is-own': isOwn,
        'paid': story.price > 0,
        'not-available': !story.isAvailable,
    }">
        <div class="StoryViewMessage-image" @click="handleClickStoryImage">
            <img :src="story.overlay_image_src" alt="Story Image">
            <div v-if="!story.isAvailable" class="StoryViewMessage-lock">
                <div class="StoryViewMessage-heart">
                    <heart-lock/>
                    <div class="StoryViewMessage-price">{{ story.price }}{{ currency }}</div>
                </div>
                <div class="StoryViewMessage-unlock">
                    <gradient-button @click="handleUnlock" class="ma-2 button-story-unlock enable-events">{{ $t('dialogs.unlock') }}</gradient-button>
                </div>
            </div>
        </div>
        <story-view-fullscreen
            v-if="isShowStory"
            :story="story"
            @close="handleCloseStoryView"
        />
        <insufficient-funds-dialog
            v-if="insufficientFundsError"
            @close="handleCloseInsufficientFundsError"
        />
        <unlock-content-dialog
            v-if="unlockDialog"
            :price="story.price"
            @close="handleCloseUnlockDialog"
            @unlock="doUnlock"
        />
    </div>
</template>

<script>
import StoryViewFullscreen from "./StoryViewFullscreen"
import GradientButton from "@/components/app/button/GradientButton"
import HeartLock from "@/assets/images/icons/heart-lock.svg?inline"
import InsufficientFundsDialog from "@/components/app/dialogs/InsufficientFundsDialog"
import UnlockContentDialog from "@/components/app/dialogs/UnlockContentDialog"
import {CURRENCY_SYMBOL} from "@/configs/constants"
import {mapActions} from "vuex";

export default {
    name: 'StoryViewMessage',
    components: {
        StoryViewFullscreen,
        HeartLock,
        GradientButton,
        InsufficientFundsDialog,
        UnlockContentDialog,
    },
    data: () => ({
        currency: CURRENCY_SYMBOL,
        insufficientFundsError: false,
        unlockDialog: false,
        isShowStory: false,
    }),
    props: {
        story: {
            type: Object
        },
        isOwn: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        ...mapActions([
            'unlockStory',
        ]),
        handleClickStoryImage() {
            this.isShowStory = this.story.isAvailable
        },
        handleCloseStoryView() {
            this.isShowStory = false
        },
        handleUnlock() {
            this.unlockDialog = true
        },
        handleCloseInsufficientFundsError() {
            this.insufficientFundsError = false
        },
        handleCloseUnlockDialog() {
            this.unlockDialog = false
        },
        async doUnlock() {
            this.unlockDialog = false
            await this.unlockStory(this.story.id).then(res => {
                if (res.status) {
                    this.$emit('reload-chat')
                } else if (res.status === false) {
                    this.insufficientFundsError = true
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.StoryViewMessage {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    &.is-own {
        justify-content: flex-end;
    }

    &-image {
        border-radius: 24px;
        width: $message-static-width;
        height: 270px;
        overflow: hidden;
        position: relative;
        display: flex;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &-lock {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &-heart {
        width: 70px;
        height: 94px;
        position: relative;
        margin-top: 10px;

        & > svg{
            width: 70px !important;
            height: 94px !important;
        }
    }

    &-price {
        position: absolute;
        top: 44px;
        width: 100%;
        text-align: center;
        color: #FE4811;
        font-size: 1.2em;
    }

    &-unlock {
        margin-top: 20px;
    }

}
</style>
