<template>
  <div class="audio-message-content mr-2" :class="{ 'my-message': isOwn }">
      <button class="play-pause-btn" href="#" @click.prevent="togglePlay">
          <!-- <svg key="pause" v-if="playing" x="0px" y="0px" viewBox="0 0 18 20">
              <path d="M17.1,20c0.49,0,0.9-0.43,0.9-0.96V0.96C18,0.43,17.6,0,17.1,0h-5.39c-0.49,0-0.9,0.43-0.9,0.96v18.07c0,0.53,0.4,0.96,0.9,0.96H17.1z M17.1,20" fill="#fff"/>
              <path d="M6.29,20c0.49,0,0.9-0.43,0.9-0.96V0.96C7.19,0.43,6.78,0,6.29,0H0.9C0.4,0,0,0.43,0,0.96v18.07C0,19.57,0.4,20,0.9,20H6.29z M6.29,20" fill="#fff"/>
          </svg>
          <svg key="play" v-else x="0px" y="0px" viewBox="0 0 18 22">
              <path d="M17.45,10.01L1.61,0.14c-0.65-0.4-1.46,0.11-1.46,0.91V20.8c0,0.81,0.81,1.32,1.46,0.91l15.84-9.87C18.1,11.43,18.1,10.41,17.45,10.01L17.45,10.01z M17.45,10.01" fill="#fff"/>
          </svg> -->
		  <v-icon v-if="playing">mdi-pause</v-icon>
		  <v-icon v-else>mdi-play</v-icon>
      </button>

      <div class="controls">
          <span class="current-time" style="color: #f5f4e5;">{{ currentTime }}</span>
          <div class="slider" data-direction="horizontal">
              <div class="progress" :style="{width: percent}"></div>
          </div>
          <span class="total-time" style="color: #f5f4e5;">{{ totalTime }}</span>
      </div>

      <audio ref="player" :src="audioSrc" @timeupdate="updateProgress" @loadedmetadata="loadedMeta" @ended="ended"></audio>
  </div>
</template>

<script>
	export default {
		name: "AudioPlayer",
		props: {
			audioSrc: {
				type: String,
				required: true
			},
			isOwn: {
				type: Boolean,
				default: true
			}
      	},
		data: () => ({
			loaded: false,
			playing: false,
			player: null,
			percent: '0%',
			currentTime: '00:00',
			totalTime: '00:00',
			draggableClasses: ['pin'],
			currentlyDragged: null
		}),
		methods: {
        	formatTime(time) {
				var min = Math.floor(time / 60)
				var sec = Math.floor(time % 60)
				return min + ':' + ((sec<10) ? ('0' + sec) : sec)
          	},
			isDraggable(el) {
				let canDrag = false;
				let classes = Array.from(el.classList);
				this.draggableClasses.forEach(draggable => {
					if(classes.indexOf(draggable) !== -1)
					canDrag = true;
				})
				return canDrag;
			},
          	loadedMeta() {
          		this.totalTime = this.formatTime(this.player.duration)
          	},
			canPlay() {
				this.loaded = true
			},
			togglePlay() {
				if(this.player.paused) {
					this.playing = true
					this.player.play()
				} else {
					this.playing = false
					this.player.pause()
				}
			},
			updateProgress() {
				var current = this.player.currentTime
				var percent = (current / this.player.duration) * 100
				this.percent = `${percent}%`

				this.currentTime = this.formatTime(current)
			},
			ended() {
				this.playing = false
				this.player.currentTime = 0
			}
    	},
		mounted() {
			this.player = this.$refs.player

			let component = this

			window.addEventListener('mousedown', function(event) {
				if(!component.isDraggable(event.target)) return false;

				component.currentlyDragged = event.target;
				let handleMethod = component.currentlyDragged.dataset.method;

				window.addEventListener('mousemove', component[handleMethod], false);

				window.addEventListener('mouseup', () => {
					component.currentlyDragged = false;
					window.removeEventListener('mousemove', component[handleMethod], false);
				}, false);
			});
      	},
  	};
</script>

<style scoped lang="scss">
	@import '@/sass/modules/_variables';

	.audio-message-content {
		width: 90%;
		max-width: 350px;
		height: 56px;
		box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .07);
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 8px;
		padding-right: 20px;
		border-radius: 24px;
		user-select: none;
		-webkit-user-select: none;
		background-color: #313131;

		.play-pause-btn {
			position: relative;
			width: 40px;
			height: 40px;

			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			svg {
				width: 17px;
				height: 17px;
			}
		}

		.slider {
			flex-grow: 1;
			background-color: #D8D8D8;
			cursor: pointer;
			position: relative;

			.progress {
				background-color: #ffae00;
				border-radius: inherit;
				position: absolute;
				pointer-events: none;

				.pin {
					height: 16px;
					width: 16px;
					border-radius: 8px;
					background-color: #44BFA3;
					position: absolute;
					pointer-events: all;
					box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.32);
				}
			}
		}

		.controls {
			font-family: 'Roboto', sans-serif;
			font-size: 16px;
			line-height: 18px;
			color: #55606E;
			display: flex;
			flex-grow: 1;
			justify-content: space-between;
			align-items: center;

			.slider {
				margin-left: 16px;
				margin-right: 16px;
				border-radius: 2px;
				height: 4px;

				.progress {
					width: 0;
					height: 100%;

					.pin {
						right: -8px;
						top: -6px;
					}
				}
			}

			span {
				cursor: default;
			}
		}
	}

	svg, img {
		display: block;
	}

	.my-message {
		background-image: $accent-background;
	}
</style>
