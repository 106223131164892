import { render, staticRenderFns } from "./TipMenuDialog.vue?vue&type=template&id=4a33dbc7&scoped=true&"
import script from "./TipMenuDialog.vue?vue&type=script&lang=js&"
export * from "./TipMenuDialog.vue?vue&type=script&lang=js&"
import style0 from "./TipMenuDialog.vue?vue&type=style&index=0&id=4a33dbc7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a33dbc7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCol,VRow,VTextField,VTextarea})
