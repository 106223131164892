<template>
    <app-transition>
        <Dialog class="DirectMessage" @close="handleClose">
            <v-row text-center="">
                <v-col>
                    <div class="DirectMessage-title">{{ $t('dialogs.report') }}</div>
                </v-col>
            </v-row>
            <v-row class="fill-width">
                <v-col cols="12">
                    <v-textarea
                        auto-grow
                        full-width
                        filled
                        outlined
                        :placeholder="$t('dialogs.report_placeholder')"
                        v-model="message">
                    </v-textarea>
                </v-col>
                <v-col cols="12">
                    <v-btn outlined block height="50" class="flex-column" @click="handleReport">
                        <div><b>{{ $t('dialogs.send') }}</b></div>
                    </v-btn>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>


<script>
import Dialog from "@/components/app/form/Dialog";

export default {
    name: "reportMessageDialog",
    data: () => ({
        message: ''
    }),
    methods: {
        handleClose() {
            this.$emit('close');
        },
        handleReport() {
            this.$emit('report', this.message);
        },
    },
    components: {
        Dialog,
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.DirectMessage {
    &-title {
        font-size: 20px;
        font-weight: bold;
        line-height: 19px;
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
    }
    &-description {
        display: flex;
        margin-top: 18px;
        padding: 9px;
        background-color: #FFFFFF34;
        border: 1px solid #FFFFFF4D;
        border-radius: 15px;
        text-align: center;
        font-size: 15px;
        line-height: 21px;
        letter-spacing: 0.45px;
        color: #FFFFFF;
    }
    .v-btn {
        text-transform: initial;
    }
    &-price {
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0px;
    }
}

</style>
<style>
button.flex-column .v-btn__content {
    flex-direction: column !important;
}
</style>
