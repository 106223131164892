<template>
    <div class="StoryControlBlock">
        <div class="StoryControlBlock-profile">
            <div class="StoryControlBlock-profileImage">
                <div class="StoryControlBlock-avatar" v-if="isHomePage">
                    <round-image :src="avatarImage" :username="name" width="40" height="40"/>
                    <follow-button
                        class="story"
                        :is-following="companion.isFollowing"
                        :user-id="userId"
                        @click="clickFollow"
                    />
                </div>
                <div class="StoryControlBlock-avatar" v-else>
                    <router-link class="StoryControlBlock-avatar"
                                 :to="{name: 'otherUser.profile.viewSlug', params: {slug: companion.slug, from: 'content', uid: companion.id}}">
                        <round-image :src="avatarImage" :username="name" width="40" height="40"/>
                    </router-link>
                    <follow-button
                        class="story"
                        :is-following="companion.isFollowing"
                        :user-id="userId"
                        @click="clickFollow"
                    />
                </div>
            </div>
            <div>
                <div v-if="isHomePage" @click="handleClickProfile">
                    <div class="name d-flex flex-row align-center">
                        {{ name }}
                        <verified-user v-if="verified" class="ml-2" width="12" height="12"/>
                    </div>
                    <div class="small">{{ smallText }}</div>
                </div>
                <router-link v-else :to="{name: 'otherUser.profile.viewSlug', params: {slug: companion.slug, from: 'content', uid: companion.id}}">
                    <div class="name d-flex flex-row align-center">
                        {{ name }}
                        <verified-user v-if="verified" class="ml-2" width="12" height="12"/>
                    </div>
                    <div class="small">{{ smallText }}</div>
                </router-link>
            </div>
        </div>
        <div
            class="StoryControlBlock-action"
            :class="{
            'unavailable': !isAvailable,
            'cant-paid': !canPaid,
        }"
        >
            <div v-if="isAvailable && companion">
                <send-message
                    :companion="companion"
                    :width="23"
                    :height="23"
                    @open-chat-box="$emit('open-chat-box')"
                    @close-chat-box="$emit('close-chat-box')"
                />
            </div>
            <div class="iconStory-wrapper"
                 v-if="isAvailable && isVideo"
                 @click="$emit('repeat')"
            >
                <v-icon size="20" :color="isRepeat ? 'orange' : 'white'">mdi-repeat</v-icon>
            </div>
            <div v-if="canPaid" @click="$emit('tips')">
                <icon-paid/>
            </div>
            <div v-if="isAvailable" @click="$emit('report')">
                <icon-complain/>
            </div>
            <div class="iconStory-wrapper" v-if="!isAvailable && isVideo">
                <span class="iconStory-duration">{{ videoDurationView }}</span>
                <icon-video-camera/>
            </div>
            <div class="iconStory-wrapper" v-if="!isAvailable && !isVideo">
                <icon-camera/>
            </div>
<!--            <div class="iconStory-wrapper" v-if="isVideo">-->
<!--                <v-icon v-if="soundOn" @click="$emit('sound')">mdi-volume-high</v-icon>-->
<!--                <v-icon v-if="!soundOn" @click="$emit('sound')">mdi-volume-off</v-icon>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
import RoundImage from "@/components/app/story/RoundImage";
import FollowButton from "@/components/app/button/FollowButton";
import IconPaid from "@/assets/images/icons/icon-paid.svg?inline";
import IconComplain from "@/assets/images/icons/icon-complain.svg?inline";
import IconCamera from "@/assets/images/icons/camera.svg?inline";
import IconVideoCamera from "@/assets/images/icons/video-camera.svg?inline";
import SendMessage from "@/components/app/message/SendMessage";
import VerifiedUser from "@/assets/images/icons/verified-user.svg?inline";
import {mapActions, mapState} from "vuex";
import {STORY_ITEM_LENGTH_TIME} from "@/configs/constants";

export default {
    name: "StoryControlBlock",
    components: {
        RoundImage,
        FollowButton,
        SendMessage,
        IconPaid,
        IconComplain,
        IconCamera,
        IconVideoCamera,
        VerifiedUser,
    },
    data() {
        return {
            companion: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.otherUser.user,
        }),
        isHomePage() {
            return ['otherUser.profile.view', 'otherUser.profile.viewSlug'].includes(this.$route.name) && parseInt(this.$route.params.id) === this.userId
        },
        videoDurationView() {
            let length = STORY_ITEM_LENGTH_TIME
            if (this.videoDuration && this.videoDuration !== 0) {
                length = this.videoDuration
            }
            const duration = this.moment.duration(length, 's')
            const minutes = duration.minutes()
            const seconds = duration.seconds()
            return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`
        },
        avatarImage() {
          if (this.profileImage) {
              return this.profileImage
          }
          return ''
        },
        canPaid() {
          return this.verified && this.isAvailable && this.noPaid === false
        },
    },
    props: {
        profileImage: {
            type: String,
        },
        name: {
            type: String,
            required: true
        },
        smallText: {
            type: String,
            required: true
        },
        noPaid: {
            type: Boolean,
            default: false
        },
        userId: {
            type: Number,
            required: true
        },
        isAvailable: {
            type: Boolean,
            default: false
        },
        verified: {
            type: Boolean,
            default: false
        },
        isVideo: {
            type: [Boolean, Number],
            default: false
        },
        videoDuration: {
            type: Number,
            default: STORY_ITEM_LENGTH_TIME
        },
        soundOn: {
            type: Boolean,
            default: false
        },
        isRepeat: {
            type: Boolean,
        }
    },
    methods: {
        ...mapActions([
            'getUserById',
        ]),
        handleClickProfile() {
            this.$emit('close-story-view')
        },
        async loadData() {
            if (!this.companion || this.user.id !== this.userId) {
                await this.getUserById(this.userId).then((res) => {
                    this.companion = res
                })
            } else {
                this.companion = this.user
            }
        },
        async clickFollow() {
            await this.getUserById(this.userId).then((res) => {
                this.companion = res
            })
        },
    },
    watch: {
        userId() {
            this.loadData()
        }
    },
    async mounted() {
        await this.loadData()
    },
}
</script>

<style scoped lang="scss">
.name {
    font-size: 1.1em;
}

.small {
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.4);
    margin-top: -3px;
}

.plus {
    width: 14px;
    height: 14px;
    background: white;
    border: solid 1px #1A1B15;
    border-radius: 20px;
}

.iconStory {
    &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        svg path {
            fill: white;
        }
    }

    &-duration {
        margin-right: 9px;
        font-size: 17px;
    }
}

.StoryControlBlock {
    display: flex;
    justify-content: space-between;
    align-items: self-start;
    padding: 0.5em 1em;

    &-profile {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        max-width: calc(100% - 150px);
        cursor: pointer;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.4);

        &Image {
            margin-right: 0.5em;
        }

        .small {
            color: #FFFFFFAA;
        }
    }

    &-action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        min-width: 128px;
        height: 47px;

        &.unavailable {
            justify-content: flex-end;
        }

        &.cant-paid {
            justify-content: flex-end;
        }

        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1.5em;
        }
    }

    &-avatar {
        position: relative;

        .follow-button {
            width: 20px;
            height: 20px;
            right: -5px;
            bottom: 0px;
            z-index: 999;
        }
    }
}
</style>
