<template>
    <div>
        <story-layout
            :story="story"
            :src="story.overlay_src"
            :overlay="story.overlay_src"
            :is-overlay-show="isOverlayShow"
            :show-overlay-controls="showOverlayControls"
            :stop-video="isStopVideo"
            v-touch="{
            up: ()=> handleTouchUp(),
            down: ()=> handleTouchDown(),
        }"
            @touchmove.native.stop.prevent
            :type="story.is_video ? 'video' : 'image'"
        >
            <unavailable
                :is-available="!story.isAvailable"
                :price="story.price"
                :user-id="userId"
                :subscribe-price="subscribePrice"
                v-if="!story.isAvailable"
                @unlock-story="handleUnlockStory"
            />
            <auth-user-label :show="story.isAvailable"/>
            <bottom-story-block
                :type="story.type"
                :slug="story.slug"
                :text="text"
                :tags="story.tags"
                v-show="true"
            />
        </story-layout>
        <swipe-tip-menu
            :is-show="isShowTip"
            :target-verified="story.verified"
            :target-username="name"
            :has-radio="false"
            :blur="true"
            @close="handleCloseTip"
            @save="sendTip"
        />
        <insufficient-funds-dialog
            v-if="insufficientFundsError"
            @close="handleCloseInsufficientFundsError"
        />
        <app-transition>
            <own-story-menu @close="isShowEdit =  false" v-if="isShowEdit"/>
        </app-transition>
        <app-transition>
            <report-message-dialog
                v-if="showReportDialog"
                @report="handleSendReport"
                @close="closeReportDialog"
            />
        </app-transition>
    </div>

</template>

<script>
import StoryLayout from "@/layouts/app/postingLayout/StoryLayout";
import {mapActions, mapMutations} from 'vuex'
import BottomStoryBlock from "@/components/app/story/BottomStoryBlock";
import AuthUserLabel from "@/components/app/story/AuthUserLabel";
import SwipeTipMenu from "@/components/app/profile/SwipeTipMenu";
import Unavailable from "@/components/app/story/Unavailable";
import OwnStoryMenu from "@/views/app/story/OwnStoryMenu";
import InsufficientFundsDialog from "@/components/app/dialogs/InsufficientFundsDialog";
import ReportMessageDialog from "@/components/app/dialogs/ReportMessageDialog";
import {INSUFFICIENT_FUNDS_ERROR, LATENCY_SHOWING_STORY} from "@/configs/constants";

export default {
    name: "StoryViewFullscreen",
    data: () => ({
        activeStory: 0,
        isShowTip: false,
        isShowEdit: false,
        showOverlayControls: true,
        own: false,
        showContent: true,
        insufficientFundsError: false,
        showReportDialog: 0,
        latencyShowingStory: LATENCY_SHOWING_STORY,
    }),
    props: {
        story: {
            type: Object,
            required: true
        }
    },
    computed: {
        profileImage() {
            return this.story.profileImage
        },
        name() {
            return this.story.name
        },
        online() {
            return this.story.online
        },
        creatorId() {
            return this.story.creator_id
        },
        subscribePrice() {
            return this.story.subscribePrice
        },
        isOverlayShow() {
            return this.story.isOverlayShow
        },
        isStopStory() {
            return this.story.isStopStory
        },
        isStopVideo() {
            return this.story.isStopVideo
        },
        isShowOverlay() {
            return !this.isShowEdit && !this.isShowTip
        },
        tags() {
            return this.currentStory.type === 'tags' ? this.currentStory.tags : []
        },
        text() {
            return this.story.text
        },
        noPaid() {
            return typeof this.story.noPaid !== 'undefined'
        },
        type() {
            return this.story.type;
        },
        userId() {
            return this.story.user_id;
        },
    },
    watch: {
        isShowTip() {
            this.showOverlayControls = !this.isShowTip
        },
    },
    methods: {
        ...mapMutations([
            'setStopStory',
            'toggleStopStory',
            'toggleStopVideo',
        ]),
        ...mapActions([
            'sendMessage',
            'sendUserWatchStory',
            'sendReportStory',
            'unlockStory',
            'setOtherUser',
        ]),
        showMenu() {
            if (this.own === true) {
                this.isShowEdit = true
            } else {
                this.showOverlayControls = false
                this.isShowTip = true
            }
        },
        close() {
            if (this.isShowTip === true) {
                this.showOverlayControls = true
                this.isShowTip = false
            } else {
                this.$emit('close')
            }
        },
        async sendTip(model) {
            await this.sendMessage({
                user_id: this.user_id,
                message: model.text,
                story_id: this.story.id,
                ...model
            }).then(() => {
                this.handleSendMessage()
            }).catch((e) => {
                if (e.response.data.err_key === INSUFFICIENT_FUNDS_ERROR) {
                    this.insufficientFundsError = true
                }
            })
        },
        handleCloseTip() {
            this.isShowTip = false
        },
        handleSendMessage() {
            // TO DO check send message
            // this.$router.push({name: 'user.own.chat', params: {id: this.creatorId}})
        },
        handleCloseInsufficientFundsError() {
            this.insufficientFundsError = false
        },
        handleTouchUp() {
        },
        handleTouchDown() {
            this.close()
        },
        handlePressMiddle() {
            this.showContent = !this.showContent
        },
        handleDblClickMiddle() {
            this.toggleStopStory()
            this.toggleStopVideo()
        },
        handleWatchStory(story) {
            this.sendUserWatchStory(story.id)
        },
        openReportDialog() {
            this.showReportDialog = this.story.id
        },
        closeReportDialog() {
            this.showReportDialog = 0
        },
        handleSendReport(message) {
            this.sendReportStory({story_id: this.showReportDialog, accusation: message}).then(() => {
                this.$store.commit('showSnackBar', this.$t('messages.success'))
            })
            this.closeReportDialog()
        },
        async handleUnlockStory() {
            await this.unlockStory(this.story.id).then(res => {
                if (res.status) {
                    this.$emit('reload-story')
                } else if (res.status === false) {
                    this.insufficientFundsError = true
                }
            })
        }
    },
    components: {
        OwnStoryMenu,
        Unavailable,
        SwipeTipMenu,
        BottomStoryBlock,
        AuthUserLabel,
        StoryLayout,
        InsufficientFundsDialog,
        ReportMessageDialog,
    },
    mounted() {
        this.handleWatchStory(this.story)
    }
}
</script>

<style scoped lang="scss">
.top-story-line {
    top:0px;
    left:0px;
    height: 1em;
    width: 100vw;
    padding-top: 0.8em;
    padding-left: 0.7em;
    padding-right: 0.7em;
    z-index: 3;
    position: absolute;
}
.top-story-block {
    position: fixed;
    top:1em;
    left: 0px;
    width: 100vw;
    z-index: 101;
}
</style>
