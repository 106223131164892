<template>
	<div class="voice-recorder">
		<button
			class="record-button"
			:class="{'is-recording': isRecording}"
			@touchstart="startRecording"
			@touchend="stopRecording"
			@touchmove="handleTouchMove"
		>
			<span>
				<v-icon :size="micSize" :color="micColor">mdi-microphone-outline</v-icon>
			</span>
		</button>

	    <div
			v-if="isRecording"
			class="slide-to-cancel"
			:style="{ left: slideTextLeft + 'px' }"
    	>
			<div class="timer">
        		<img :src="require('@/assets/recording_animation.gif')" alt="Loading..." />
          		{{ formattedTimer }}
        	</div>

			<div class="cancel">
				<v-icon size="22">mdi-chevron-left</v-icon>
				<span>{{ $t("messages.slide_to_cancel") }}</span>
			</div>
    	</div>
  </div>
</template>
  
<script>
import { AUDIO_MESSAGE_LENGTH_TIME } from "@/configs/constants";
import EventBus from '../../../event-bus.js';
import moment from "moment";

export default {
    name: "AudioRecorder",
    data() {
    	return {
			isRecording: false,
			slideTextLeft: 0,
			startX: 0,
			recordingTimer: null,
			timer: 0,
			timerInterval: null,
      	};
    },
	computed: {
		formattedTimer() {
			return this.timer !== false ? moment.utc(this.timer * 1000).format('mm:ss') : '';
		},
		micColor() {
			return this.isRecording ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.4)';
		},
		micSize() {
			return this.isRecording ? 46 : 24;
		},
	},
    methods: {
		startRecording(event) {
			this.slideTextLeft = 0,
			this.startX = event.type === 'mousedown' ? event.clientX : event.touches[0].clientX;
			this.recordingTimer = setTimeout(() => {
				this.isRecording = true;
				this.beginRecording();
			}, 800); // Small delay to prevent accidental taps
		},
		stopRecording() {
			if (this.isRecording) {
				clearTimeout(this.recordingTimer);
				this.isRecording = false;
				this.finishRecording();
			} else {
				clearTimeout(this.recordingTimer);
				clearInterval(this.timerInterval);
			}
		},
		cancelRecording() {
			if (this.isRecording) {
				clearTimeout(this.recordingTimer);
				this.isRecording = false;
				this.cancelRecordingAction();
			}
		},
		handleTouchMove(event) {
			if (this.isRecording) {
				const touchX = event.touches[0].clientX;
				this.slideTextLeft = touchX - this.startX;
		
				// Check if the user has slid left enough to cancel
				if (this.slideTextLeft < -100) { // Adjust threshold as needed
					this.cancelRecording();
				}
			}
		},
		beginRecording() {
			console.log('Recording started...');
			this.timer = 0
			this.$emit('startRecordingAudio');
		},
		finishRecording() {
			clearInterval(this.timerInterval);
			console.log('Recording stopped and saved.');
			this.$emit('stopRecordingAudio');
		},
		cancelRecordingAction() {
			clearInterval(this.timerInterval);
			console.log('Recording cancelled.');
			this.$emit('cancelRecordingAudio');
		},
		startTimer() {
			clearInterval(this.timerInterval);
			this.timerInterval = setInterval(() => {
				this.timer++;
				if (this.timer % AUDIO_MESSAGE_LENGTH_TIME === 0) {
					this.stopRecording()
				}
			}, 1000);
    	}
    },
	created() {
		// Listen for the 'recordingStarted' event from the parent
		EventBus.$on('audioRecordingStarted', this.startTimer);
	},
	beforeDestroy() {
		// Clean up interval and event listeners
		clearInterval(this.timerInterval);
		EventBus.$off('audioRecordingStarted', this.startTimer);
	}
};
</script>
  
<style scoped>
	.voice-recorder {
		margin: 50px auto;
		text-align: center;
  	}
  
	.slide-to-cancel {
		position: absolute;
		top: 0;
		left: 0;
		background-color: #000;
		color: white;
		padding: 5px 10px;
		border-radius: 0;
		margin-top: 10px;
		font-size: 14px;
		transition: left 0.1s ease;
		width: calc(100% - 70px);
		margin: 0;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.slide-to-cancel .cancel  {
		animation: animate 1.5s infinite alternate ease-in-out;
	}
	.cancelled-recording {
		display: flex;
		align-items: center;
	}
	@keyframes animate {
    	0% {
        	transform: translateX(0px);
    	}
    	100% {
        	transform: translateX(-20px);
    	}
	}
</style>
  